<template>
    <div id="news-detail">
        <v-head></v-head>
        <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
            <div style="min-height: 100vh;" ref="listView">
                <div class="content" v-if="news">
                    <div class="creator">
                        <span class="icon-doctor"></span>
                        <div class="col">
                            <span class="doctor-name" v-text="news.creator"></span>
                            <span class="create-time" v-text="news.createdtime"></span>
                        </div>
                    </div>
                    <h1 class="news-title" v-text="news.title"></h1>
                    <img src="../../../assets/imgs/pc/img_fenx@2x.png" alt="" class="icon-share" @click="doShare">
                    <div class="row">
                        <span class="news-category" v-text="'【'+news.category+'】'"></span>
                        <div style="display: inline" v-if="news.tag">
                            <router-link :to="`/news/list?keyword=${tag}`" class="tag" v-for="(tag, i) in news.tag.split(/\||,|，/)" :key="i" v-text="tag"></router-link>
                        </div>
                    </div>
                    <div class="news-content" v-html="news.content"></div>
                </div>
            </div>
        </van-pull-refresh>
        <loading v-if="loading"/>
        <my-share :data="shareData" v-if="share" :onResult="()=>share=false"/>
    </div>
</template>

<script>
    import MyShare from '@/layouts/MyShare.vue'
    import news_detail from '@/views/mixins/news_detail'
    import Loading from '@/layouts/Loading.vue'
    import vHead from '@/layouts/MobileHead.vue'
	export default {
        mixins: [
            news_detail,
        ],
        components: {
            vHead,
            Loading,
            MyShare,
        },
        data: function(){
            return {
                share: false,
                shareData: null,
                isRefresh: true,
                news: null,
                loading: true,
            }
        },
        mounted: function(){
            var newsId = this.$route.params.newsId
            if(newsId == parseInt(newsId)) {
                this.news = JSON.parse(sessionStorage.getItem('detail'))
                this.loading = false
            } else {
                this.$router.push('/news/list')
            }
        },
        methods: {
            onRefresh: function(){
                var clientId = this.$store.getters.clientId || undefined
                this.loadData({'newsid': this.$route.params.newsId, 'clientid': clientId}, (data, error)=>{
                    this.loading = false
                    this.isRefresh = false
                    if(data) {
                        this.news = data
                    } else {
                        this._toast.warning(this, error)
                    }
                })
            },
            doShare: function(){
                this.shareData = {
                    url: `${location.origin}/news/detail/${this.news.newsid}`,
                    title: this.news.title,
                    baseUrl: location.origin
                }
                this.share = true
            }
        },
        watch: {
            '$route.params.newsId': function(newsId) {
                this.loading = true
                if(newsId == parseInt(newsId)) {
                    this.news = JSON.parse(sessionStorage.getItem('detail'))
                    this.loading = false
                } else {
                    this.$router.push('/news/list')
                }
            }
        }
    }
</script>

<style lang="scss">
    #news-detail {
		padding-top: 83px;
	}

    #news-detail .content {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        box-sizing: border-box;
        padding: 42px 25px;
    }

    #news-detail .creator {
        display: flex;
        flex-direction: row;
        margin-bottom: 72px;
    }

    #news-detail .creator .icon-doctor {
        width: 78px;
        height: 78px;
        background-color: #eee;
        border-radius: 50%;
        margin-right: 22px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/mobile/img_nvyis@2x.png);
    }

    #news-detail .creator .icon-doctor.man {
        background-image: url(../../../assets/imgs/mobile/img_nanysy@2x.png);
    }

    #news-detail .creator .col {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    #news-detail .creator .doctor-name {
        color: #202020;
        font-weight: bold;
        font-size: 34px;
        line-height: 34px;
    }

    #news-detail .creator .create-time {
        color: #9A9A9A;
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 15px;
    }

    #news-detail .creator .ic-share {
        margin-right: 24px;
    }

    #news-detail .content .news-title {
        color: #202020;
        font-size: 36px;
        font-weight: bold;
        line-height: 36px;
        margin-bottom: 27px;
    }

    #news-detail .content .news-category {
        color: #202020;
        font-size: 28px;
        font-weight: 400;
        line-height: 28px;
        margin-left: -10px;
        margin-right: 41px;
    }

    #news-detail .content .tag {
        margin-right: 8px;
        color: #FF6B22;
        font-size: 28px;
        line-height: 28px;
        font-weight: 400;
    }

    #news-detail .content .news-content {
        color: #343434;
        font-size: 32px;
        font-weight: 400;
        line-height: 48px;
        margin-top: 40px;
    }

    #news-detail .content .news-content img {
        width: 100% !important;
    }

    #news-detail .icon-share {
        position: absolute;
        right: 36px;
        top: 36px;
    }

</style>
